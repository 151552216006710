@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        height: 100%;
    }

    body {
        height: 100%;
        font-family: 'poppins', 'sans-serif';
        overflow-x: hidden;
    }

    #root {
        height: 100%;
    }
}